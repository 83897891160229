<template>
  <v-card min-height="400px" color="#273142">
    <v-card-title class="card-header">
    <h3>Payment Methods</h3>
  </v-card-title>

  <v-card-text class="pa-0" flat>
    <v-data-table
      :headers="headers"
      :items="transactions"
      class="transparent"
    >
      <template v-slot:item.state="{ item }">
        <v-chip :color="`${essayGreen}`" dark label small v-if="item.state">{{ item.state}}</v-chip>
      </template>
    </v-data-table>
  </v-card-text>
</v-card>
</template>

<script>
import colorMxn from '@/mixins/colorMxn';
import timezoneMxn from '@/mixins/timezone_mixin';

export default {
  name: 'paymentMethod',
  mixins: [colorMxn, timezoneMxn],
  data() {
    return{
       headers: [
        { text: "Type", value: "system_title" },
        { text: "Account ID", value: "payment_info" },
        { text: 'State', value: 'state'},
      ],
      transactions: [
        {
          id: 218574,
          system_name: "payoneer",
          system_title: "Payoneer",
          payment_info: "mordecaionwongah@gmail.com",
          actions: {
            can_delete: true,
            can_edit: false
          },
          primary: true,
          state: "verified",
          state_title: "Verified"
        }
      ]
    }
  }
}
</script>

<style>
.essay-green {
  color:#038c43;
}

tbody tr {
  border-bottom: none;
}
</style>